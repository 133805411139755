import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import { Link } from "@remix-run/react";
import { AppLayout } from "~/components/layouts/appLayout";
import { Button } from "~/components/ui/button";
import { requireGuest } from "~/services/auth.server";

export const meta: MetaFunction = () => {
  return [{ title: "マイコミュへようこそ" }];
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await requireGuest(request);
  return null;
};

export default function Index() {
  return (
    <AppLayout className="flex flex-col">
      <div className="flex flex-1 items-center justify-center">
        <div className="flex flex-col items-center">
          <img
            src="/common/logo-my-commu.svg"
            width="141"
            height="213"
            alt="マイコミュ by AsMama"
          />

          <ul className="mt-6 flex space-x-4 text-sm">
            <li className="underline">日本語</li>
            <li>English</li>
            <li>中文 (简体)</li>
            <li>नेपाली</li>
          </ul>
        </div>
      </div>

      <div className="mx-8 my-10">
        <Link to="/auth/signin">
          <Button className="w-full font-bold">ログイン</Button>
        </Link>

        <Link to="/auth/signup">
          <Button variant="secondary" className="mt-4 w-full font-bold">
            新規アカウント登録
          </Button>
        </Link>
      </div>
    </AppLayout>
  );
}
